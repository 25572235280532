<template>
  <!-- 视频设置 -->
  <div class="home set-point">
    <el-form :model="form1" :inline="true" :rules="rule1" ref="form1">
      <p>测点信息</p>
      <el-form-item class="long-input" label="视频编号" prop="videoName">
        <el-input v-model="form1.videoName" placeholder="请输入测点编号" clearable @input="change($event)" />
      </el-form-item>
      <el-form-item label="视频源类型" class="long-input" prop="sourceType">
        <el-select v-model="form1.sourceType" placeholder="请选择" @change="changeSourceType">
          <el-option v-for="itm in videoSourceType" :key="itm.id" :label="itm.name" :value="itm.id" />
        </el-select>
      </el-form-item>

      <el-form-item label="视频类型" class="long-input" prop="type">
        <!-- <el-input v-model="form1.site" placeholder="请输入" clearable /> -->
        <el-select v-model="form1.type" placeholder="请选择" @change="changetype">
          <el-option v-for="item in videoType" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="位置描述" class="long-input" prop="site">
        <el-input v-model="form1.site" placeholder="请输入" clearable />
      </el-form-item> -->
      <el-form-item label="安装时间" class="long-input" prop="site">
        <el-date-picker v-model="form1.createTimeStr" type="datetime" placeholder="选择日期" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"> </el-date-picker>
      </el-form-item>

      <el-form-item v-if="form1.sourceType == 1" label="视频流路径" class="long-input long-input1" prop="videoUrl">
        <el-input v-model="form1.videoUrl" placeholder="请输入" clearable @input="change($event)" />
      </el-form-item>

      <el-form-item v-if="form1.sourceType == 2" label="萤石云直播源" class="long-input long-input1" prop="candDeviceSerial">
        <el-input v-model="form1.candDeviceSerial" placeholder="请输入" clearable @input="change($event)" />
      </el-form-item>
      <el-form-item v-if="form1.sourceType == 2" label="萤石云Appkey" class="long-input long-input1" prop="candAppkey">
        <el-input v-model="form1.candAppkey" placeholder="请输入" clearable @input="change($event)" />
      </el-form-item>
      <el-form-item v-if="form1.sourceType == 2" label="萤石云Secret" class="long-input long-input1" prop="candSecret">
        <el-input v-model="form1.candSecret" placeholder="请输入" clearable @input="change($event)" />
      </el-form-item>
      <el-form-item label="坐标" class="long-input long-input1" prop="coordinate">
        <el-input v-model="form1.coordinate" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item label="摄像头型号" class="long-input" prop="site">
        <el-input v-model="form1.model" placeholder="请输入" clearable />
      </el-form-item>

      <el-form-item label="" prop="">
        <el-input style="visibility: hidden"></el-input>
      </el-form-item>
    </el-form>
    <!-- 
    <el-form :model="form1" :inline="true" :rules="rule1" ref="form1">
      <p>测点信息</p>
      <el-form-item class="long-input" label="测点编号" prop="code">
        <el-input v-model="form1.code" placeholder="请输入测点编号" clearable />
      </el-form-item>
    </el-form> -->

    <el-form :model="ruleForm" ref="ruleForm">
      <div class="formMain mT20">
        <p class="">封面上传</p>
        <div class="box center flexW">
          <div class="el-form-item docItemMain">
            <div class="el-form-item__label requiredTip box center horizontal"><span>封面</span></div>
            <el-form-item class="flex1" prop="imagesId">
              <el-upload
                :class="isDisabled ? 'hideUpload' : ''"
                :disabled="isDisabled"
                :action="upBridgeImgUrl"
                :headers="uploadHeaders"
                multiple
                :limit="limitNum"
                :file-list="bridgeImgList"
                :on-change="handleAddChange"
                :on-exceed="handleExceed"
                list-type="picture-card"
                :on-remove="handleAddRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>

    <div class="btns box around">
      <!-- <el-button @click="handleSave">保存</el-button>
      <el-button @click="$router.go(-1)">返回</el-button> -->
      <div class="box">
        <div class="btnSelect2 mR20 pointer" @click="handleSave" style="width: 100px">保存</div>
        <div class="btnSelect1 pointer" @click="$router.go(-1)" style="width: 100px">返回</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      projectId: "",
      form1: {
        videoName: "",
        videoUrl: "",
        coordinate: "",
        createTimeStr: "",
        model: "",
        sourceType: 1,
        type: "",
        candDeviceSerial: "", // 萤石云直播源
        candAppkey: "", // 萤石云帐号
        candSecret: "" // 萤石云密码
      },
      rule1: {
        videoName: [{ required: true, message: "请输入视频编号", trigger: "blur" }],
        videoUrl: [{ required: true, message: "请输入测点流路径", trigger: "blur" }],
        type: [{ required: true, message: "请选择视频类型", trigger: "change" }],
        sourceType: [{ required: true, message: "请选择视频源类型", trigger: "change" }],
        candDeviceSerial: [{ required: true, message: "请输入萤石云路径", trigger: "blur" }],
        candAppkey: [{ required: true, message: "请输入萤石云Appkey", trigger: "blur" }],
        candSecret: [{ required: true, message: "请输入萤石云Secret", trigger: "blur" }]
      },
      videoSourceType: [
        {
          id: 1,
          name: "流"
        },
        {
          id: 2,
          name: "萤石云"
        }
      ],
      videoType: [{ id: "1", name: "安防类" }],
      ruleForm: {},

      // 上传图片
      bridgeImgList: [],
      upBridgeImgUrl: "",
      uploadHeaders: {},
      limitNum: 1,
      isDisabled: false,

      // id
      targetId: ""
    };
  },
  //创建
  created() {
    // 上传  header头， 测点上传地址
    this.uploadHeaders = { token: window.sessionStorage.getItem("token") };
    this.upBridgeImgUrl = this.baseURL + "sys/sysImages/save/video";
    this.projectId = sessionStorage.getItem("projectId");
    this.currentPage = this.$route.query.currentPage;
  },

  //安装
  mounted() {
    let name = "";
    this.targetId = this.$route.query.id;
    if (this.$route.query.type == "edit") {
      name = "编辑视频";
      //  读取
      this.getData();
      this.getBridgeImg(); //编辑时读取照片
      this.$forceUpdate();
    } else {
      name = "添加视频";
    }

    let arr = this.$store.state.breadcrumbList;
    if (arr.length < 4) {
      arr.push({ name });
      this.$store.commit("getBreadcrumb", arr);
    }
  },
  beforeDestroy() {
    let arr = this.$store.state.breadcrumbList;
    console.log("测点面包屑列标111", arr);

    arr.splice(3);
    this.$store.commit("getBreadcrumb", arr);
    console.log("测点面包屑列标", arr);
  },
  //方法
  methods: {
    change(e) {
      this.$forceUpdate();
    },

    // 上传change事件
    handleAddChange(file, fileList) {
      this.bridgeImgList = fileList;
      console.log("bridgeImgList", this.bridgeImgList);
    },
    // 多选大于限制个数时做提示
    handleExceed(file, fileList) {
      this.$message({
        message: "上传文件超出限制个数！",
        type: "warning"
      });
    },
    // 移除文件
    handleAddRemove(file, fileList) {
      this.handleAddChange(file, fileList);
    },

    // 编辑前获取数据
    getData() {
      this.$axios.get(`${this.baseURL}base/video/${this.targetId}`).then((res) => {
        console.log("red-读取历史", res.data.data);
        this.$forceUpdate();
        var data = res.data.data;
        // this.form1.model = data.model;
        // this.form1.type = data.type;
        // this.form1.videoName = data.videoName;
        // this.form1.videoUrl = data.videoUrl;
        // this.form1.createTimeStr = data.createTime;
        this.form1 = Object.assign(this.form1, data);
        this.form1.createTimeStr = data.createTime;
        // this.form1.sourceType = data.sourceType;
        console.log("form1", this.form1);
      });
    },

    // 获取桥梁图片信息
    getBridgeImg() {
      this.$axios.get(`${this.baseURL}sys/sysImages/Video/${this.targetId}`).then((res) => {
        // console.log('读取图片', res);

        var imgList = [];
        let url = `${this.baseURL}sys/sysImages/img/${res.data.data[0].id}`;
        // console.log('url', url);

        let obj = {
          id: res.data.data[0].id,
          response: { data: res.data.data[0].id },
          url
        };
        imgList.push(obj);
        this.bridgeImgList = imgList;
      });
    },
    changeSourceType() {},
    changetype() {},
    // 提交视频地址
    handleSave() {
      if (this.bridgeImgList.length == 0) {
        this.$message.error("请选择封面");
        return;
      }

      var data = {
        model: this.form1.model,
        projectId: this.projectId,
        type: this.form1.type,
        videoName: this.form1.videoName,
        videoUrl: this.form1.videoUrl,
        coordinate: this.form1.coordinate,
        sourceType: this.form1.sourceType,
        candDeviceSerial: this.form1.candDeviceSerial,
        candAppkey: this.form1.candAppkey,
        candSecret: this.form1.candSecret
      };

      var data1 = {
        id: this.targetId,
        model: this.form1.model,
        projectId: this.projectId,
        type: this.form1.type,
        videoName: this.form1.videoName,
        videoUrl: this.form1.videoUrl,
        coordinate: this.form1.coordinate,
        sourceType: this.form1.sourceType,
        candDeviceSerial: this.form1.candDeviceSerial,
        candAppkey: this.form1.candAppkey,
        candSecret: this.form1.candSecret
      };

      var time = this.form1.createTimeStr;
      var imgid = this.bridgeImgList[0].response.data;

      console.log("form1", this.form1);
      this.$refs["form1"].validate((valid) => {
        if (valid) {
          // 编辑
          if (this.targetId) {
            // 添加
            this.$axios.post(`${this.baseURL}base/video/save?imagesIds=${imgid}&createTimeStr=${time}`, data1).then((res) => {
              console.log("保存摄像头", res);
              res = res.data;
            });
          } else {
            // 添加
            this.$axios.post(`${this.baseURL}base/video/save?imagesIds=${imgid}&createTimeStr=${time}`, data).then((res) => {
              console.log("保存摄像头", res);
              res = res.data;
            });
          }
          this.$router.push({
            path: "/devManage",
            query: {
              currentPage2: this.currentPage,
              active: 2
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep .el-input__inner {
  border: 1px solid rgba(151, 151, 151, 0.29) !important;
}

.derive,
.multi-form {
  &::v-deep .el-form-item__error {
    top: 38px;
  }
}

.trendForm::v-deep {
  .el-form-item {
    margin-right: 10px;
  }
}

@media screen and (min-width: 1750px) {
  .set-point::v-deep {
    .el-input,
    .el-input__inner {
      // width: 178px;
    }

    .el-form-item__label {
      width: 152px;
    }

    .multi-form .el-form {
      padding: 0 1% 0 6.47%;
    }

    .analyse .el-form {
      .el-form-item:first-child {
        .el-form-item__content {
          width: 198px;
        }
      }

      .interval {
        margin-left: 100px;
      }

      .el-form-item__label {
        width: 100px;
      }
    }

    .rehandle {
      .el-form-item {
        margin-right: 2%;
      }

      .el-form-item:not(:first-child) {
        .el-form-item__label {
          width: 70px;
        }
      }
    }

    .limit-value {
      .el-form-item {
        width: 258px;

        .el-input,
        .el-input__inner {
          width: 130px;
        }
      }
    }
  }
}

@media screen and (max-width: 1750px) {
  .set-point::v-deep {
    .el-input,
    .el-input__inner {
      width: 127px;
    }

    .el-form:first-child {
      .el-form-item__label {
        min-width: 122px;
        white-space: nowrap;
      }
    }

    .derive {
      .el-form-item__label {
        min-width: 110px;
        white-space: nowrap;
      }

      .el-input,
      .el-input__inner {
        width: 183px;
      }
    }

    .multi-form .el-form {
      padding-left: 20px;
    }

    .analyse section {
      .el-form-item:first-child {
        .el-form-item__content {
          width: 127px;
        }
      }

      .el-form-item:nth-child(2) {
        margin-left: 15px;
      }

      .interval {
        margin-left: 80px;
      }
    }

    .rehandle {
      .el-form-item:not(:first-child) {
        .el-form-item__label {
          width: 72px;
        }
      }

      .el-form-item {
        margin-right: 20px !important;
      }
    }

    .limit-value {
      .el-form-item {
        width: 220px;

        .el-form-item__label {
          margin-right: 10px !important;
        }

        .el-input,
        .el-input__inner {
          width: 115px;
        }
      }

      .el-form-item:nth-child(odd) {
        width: 220px;

        .el-form-item__label {
          width: 86px !important;
        }
      }
    }
  }
}

.time_length::v-deep .el-form-item__label {
  margin-left: 40px;
}

.el-form::v-deep {
  .el-form-item__label {
    padding: 0;
    height: 52px;
    line-height: 52px;
    text-align: center;
    background: #0c265a;
  }

  .red-warning .el-form-item__label {
    text-align: right;
  }

  .el-form-item__content {
    height: 52px;
    width: auto;
    line-height: 52px;
    text-align: center;
  }

  .el-input__inner {
    padding-left: 20px;
    height: 30px;
    line-height: 30px;
    // text-align: center;
  }

  .el-select {
    .el-input__inner {
      text-align: left;
      padding-left: 20px;
      border: 1px solid rgba(151, 151, 151, 0.29);
    }

    .el-input__icon {
      height: 30px;
      line-height: 30px;
    }
  }

  .el-input.is-disabled .el-input__inner {
    border: 1px solid #383e41 !important;
    background: rgba(56, 63, 65, 0.29) !important;
  }

  .el-form-item.long-input {
    width: 50% !important;

    .el-input,
    .el-input__inner {
      // width: 95% !important;
    }
  }
}

p {
  color: #fff;
  line-height: 40px;
  text-align: center;
}

.el-form-item {
  margin: 0;
  border-top: 1px solid #2c3134;
}

.el-form-item {
  display: inline-flex;

  &::v-deep {
    .el-form-item__content {
      flex: 1;
    }
  }
}

.title {
  width: 152px;
  display: flex;
  word-wrap: none;
  white-space: nowrap;
  color: #dbf804;
  align-items: center;
  justify-content: center;
  background: #0c265a;
}

.max_factorial {
  margin-left: 20px;
}

.denosing-form .title {
  border-bottom: 1px solid #2c3134;
}

.derive {
  &::v-deep {
    .el-form-item__label,
    .el-col {
      margin-right: 22px;
    }
  }
}

.multi-form,
.el-form:first-of-type,
.el-form:nth-of-type(2) {
  margin: 4px;
  border-radius: 8px;
  margin-bottom: 26px;
  // border: 1px solid #5E5E5F;
  // box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);
  background: #05193f;
}

.el-form:first-child {
  &::v-deep {
    .el-form-item__content > div:not(.el-select) {
      .el-input__inner {
        padding: 0;
        border: none;
      }
    }
  }

  .el-form-item {
    width: 50%; //25
  }

  .el-form-item:nth-of-type(5) {
    // width: 50%;
  }

  .el-form-item:nth-of-type(6) {
    //width: 50%;
    //padding-right: 42px;
  }

  .el-form-item:nth-last-of-type(1) {
    width: 50%;
  }
}

.el-form:nth-child(2) {
  .el-form-item {
    display: block;

    &::v-deep {
      .el-form-item__content {
        display: inline-flex;
      }
    }

    .el-select:not(:last-child) {
      margin-right: 22px;
    }
  }
}

.multi-form {
  section {
    display: flex;
    border-top: 1px solid #2c3134;

    p {
      width: 152px;
      height: 52px;
      line-height: 52px;
      background: #0c265a;
    }

    .el-form {
      flex: 1;
      margin: 0;
      border: none;
      display: flex;
      flex-wrap: wrap;
      box-shadow: none;

      .el-form-item {
        border: none;
        height: 52px;

        &::v-deep {
          .el-form-item__label {
            text-align: left;
            margin-right: 20px;
            white-space: nowrap;
            background: transparent;
          }
        }

        .unit {
          padding: 0 12px;
          white-space: nowrap;
        }
      }
    }
  }

  .denosing-form {
    .el-form {
      margin: 0;
      border-radius: 0;
      border-bottom: 1px solid #2c3134;
    }

    .el-form-item:nth-of-type(2) {
      width: 50%;

      &::v-deep {
        .el-form-item__content {
          white-space: nowrap;
        }
      }
    }

    .el-form-item {
      &::v-deep .el-form-item__error {
        top: 75% !important;
      }
    }
  }

  .rehandle {
    .el-form-item:first-child {
      &::v-deep .el-form-item__label {
        width: 182px;
      }
    }
  }
}

.multi-form:nth-of-type(-n + 1) {
  .el-form-item {
    margin-right: 4%;
  }
}

.set-time {
  padding: 0 2% !important;

  .el-form-item:first-child {
    margin-right: 20px;
  }
}

.set-threshold {
  .title {
    border: none;
  }

  .form-title {
    margin: 20px 0;

    span {
      width: 50%;
      display: inline-block;
    }
  }

  .form-list {
    flex: 1;
    padding: 0 0 23px 2%;

    .el-form {
      padding: 0;

      &::v-deep {
        .el-form-item__content {
          width: 78px;
        }

        .el-form-item__label {
          padding: 0;
          width: 90px;
        }
      }
    }

    .limit-value {
      width: 50%;

      .el-form-item {
        display: inline-flex;
      }
    }
  }
}

.btns {
  margin-top: 44px;
  text-align: center;

  .el-button {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }

  .el-button:last-child {
    margin-left: 50px;
    background: #1e2529;
  }
}

::v-deep .el-form {
  .formMain {
    // border: 1px solid #5E5E5F;
    // box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);
    background: #05193f;

    .title {
      color: #dbf804;
      line-height: 40px;
      text-align: center;
    }

    .el-form-item {
      width: 50%;
      min-height: 52px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      border-top: 1px solid #1b2947;

      &.borderbot {
        border-bottom: 1px solid #5e5e5f;
      }

      &.cityItem {
        .el-form-item__content {
          .el-select {
            height: 31px;
            width: 40%;

            .el-input {
              width: 100%;
              border-radius: 4px;
              border: 1px solid rgba(151, 151, 151, 0.29);
              line-height: 31px;
            }
          }
        }
      }

      &.docItemMain {
        min-height: 108px;
        position: relative;
        width: 100%;

        .el-form-item {
          border: none;
        }

        .el-form-item__label {
          height: 100%;
          position: absolute;
          min-width: 122px;
        }

        .el-form-item__content {
          line-height: 0;
          padding-left: 216px;

          & > div {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: flex-end;
            width: 100%;
          }

          .el-upload-list--picture-card {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex: 1;

            .el-upload-list__item {
              width: 80px;
              height: 60px;
              margin: 20px 0 20px 40px;
              border: none;
            }
          }

          .el-upload--picture-card {
            width: 80px;
            height: 60px;
            background: #21272b;
            border-radius: 2px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 40px;

            i {
              color: #fff;
            }
          }

          div.hideUpload {
            .el-upload--picture-card {
              display: none;
            }
          }
        }
      }

      .el-form-item__label {
        // width: 216px;
        height: 100%;
        background: #0c265a;
        // border-radius: 0px 0px 0px 8px;
        padding: 0;
        text-align: center;
        line-height: 52px;

        &.requiredTip:before {
          content: "*";
          color: #f56c6c;
          margin-right: 4px;
        }
      }

      .el-form-item__content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .el-select {
          .el-input {
            width: 178px;
            height: 31px;
            border-radius: 4px;
            border: 1px solid rgba(151, 151, 151, 0.29);
            line-height: 31px;
          }
        }

        .el-input {
          width: 100%;
          height: 52px;
          border: none;

          .el-input__inner {
            border: none;
            text-align: center;
          }

          .el-input__icon {
            line-height: 32px;
          }
        }

        .el-checkbox__inner {
          width: 20px;
          height: 20px;

          &:after {
            height: 9px;
            left: 6px;
            width: 5px;
          }
        }

        .el-form-item__error {
          left: 48px;
        }

        .tips {
          color: #f0f0f0;
          padding-left: 30px;
        }
      }
    }
  }
}

::v-deep .el-input,
.el-input__inner {
  width: 178px;
}

::v-deep .long-input1 .el-input,
::v-deep .long-input1 .el-input .el-input__inner {
  width: 100% !important;
}
</style>
